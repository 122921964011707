import React from 'react';
import {
    Container,
    Typography,
    Grid,
    Avatar,
    Divider,
    TableCell,
    TableRow,
    TableBody,
    Paper,
    TableContainer,
    Table,
} from '@mui/material';
import me from './assets/me.jpg';
import './App.css';

function ExperienceItem({ title, location, date, descriptions, stack }) {
    return (
        <Grid item xs={12}>
            <Grid container justifyContent="space-between" flexDirection="row">
                <Typography variant="h6" sx={{ fontWeight: '600' }}>
                    {title}
                </Typography>
                <Typography variant="subtitle1">{date}</Typography>
            </Grid>

            <Typography variant="subtitle1">{location}</Typography>
            <Typography variant="subtitle1">Tech Stack : {stack}</Typography>
            
            <ul style={{ textAlign: 'justify' }}>
                {descriptions.map((description, index) => (
                    <li key={index}>
                        <Typography variant="body1">{description}</Typography>
                    </li>
                ))}
            </ul>

            <Divider style={{ margin: '10px 0' }} />
        </Grid>
    );
}

function Resume() {
    const rows = [
        { type: 'Technical', items: 'JS ES6, TypeScript' },
        {
            type: 'Frameworks',
            items: 'ReactJS, Next.JS, Svelte, VueJS, Yii2, Laravel',
        },
        { type: 'Backend', items: 'PHP, NodeJS' },
        {
            type: 'Database / API',
            items: 'RestAPI, OpenAPI, MySQL, GraphQL, MongoDB, MariaDB, Prisma',
        },
        { type: 'QA / Testing', items: 'Puppeteer, Playwright, Jest' },
        {
            type: 'DevOps / Others',
            items: 'Server Management, Azure, AWS,GIT, Linux CLI, PowerBi',
        },
    ];

    const educations = [
        {
            id: 1,
            title: 'Doctor of Information Technology (9 Units)',
            date: '2019 - On Hold ',
            location: 'La Consolacion University Philippines',
        },

        {
            id: 2,
            title: 'Master  of Information Technology',
            date: '2017 – 2019',
            location: 'La Consolacion University Philippines',
        },

        {
            id: 3,
            title: 'Bachelor of Science in Information Technolog',
            date: '2012 – 2016',
            location: 'Bulacan State University',
        },
    ];

    return (
            <Container maxWidth='md'
            >
                <Grid sx={{
                    backgroundColor: '#38598b',
                    borderRadius: 5,
                    color: '#fff',
                    padding: '30px',
                    marginBottom: 30,
                    boxShadow: '5px 5px 15px 5px rgba(0, 0, 0, 0.5)',
                    marginTop:'20vh'
                }}>
                <Grid
                    container
                    alignItems="center"
                    sx={{ marginBottom: '1rem',marginTop:'-15vh' }}
                    direction={'column'}
                >
                    <Avatar
                        src={me}
                        alt="Profile Picture"
                        style={{
                            width: 200,
                            height: 200,
                            border: '5px solid #fff',
                        }}
                    />
                </Grid>
                <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    direction={'column'}
                >
                    <Grid item>
                        <Typography variant="h4" sx={{ textAlign: 'center' }}>
                            Jade Christian C. Sayo, MSIT
                        </Typography>
                        <Typography variant="subtitle1">
                            jadsayo@outlook.com | me@jadsayo.com • 09165811140 •
                            jadsayo.com
                        </Typography>
                    </Grid>
                </Grid>

                {/* Experiences Section */}
                <Typography variant="h6" style={{ marginTop: 30 }}>
                    Experiences
                </Typography>
                <Divider style={{ margin: '10px 0' }} />
                <Grid container spacing={2}>
                    <ExperienceItem
                        title="Project Lead / Owner"
                        location="Hapi Healthcare, Ortigas, Metro Manila"
                        date="March 2023 – January 2024"
                        descriptions={[
                            'Led transformative project, boosting team productivity by 25% and cutting timelines by 15%, earning recognition from senior management.',
                            'Executed high-stakes project, surpassing goals and increasing client satisfaction by 30%, securing repeat business opportunities.',
                        ]}
                        stack="Yii2, Javascript, TypeScript, PHP, SASS, Playwright, Azure"
                    />
                    <ExperienceItem
                        title="Data Analytics Developer"
                        location="NClarity, Alianna Loop"
                        date="September 2023 – October 2023"
                        descriptions={[
                            'Implemented a data optimization strategy resulting in 50% accuracy improvement and 30% better predictive modeling, acknowledged for significant business impact.',
                            'Developed advanced data analytics solutions, achieving a 40% efficiency boost and 20% faster insights, setting new industry standards.',
                        ]}
                        stack="NodeJs, Javascript, Typescript, PowerBi, Prisma, Playwright, AWS"
                    />
                    <ExperienceItem
                        title="Project Lead"
                        location="Vericle Pacific Inc., Alabang, Muntinlupa, Metro Manila"
                        date="July 2021 – February 2023"
                        descriptions={[
                            'Oversaw and guide multiple web development projects, resulting in a 20% improvement in delivery time and a 15% increase in project efficiency.',
                            'Facilitated transparent communication with stakeholders, team members, and senior management, leading to a 30% enhancement in project alignment and a 18% rise in client satisfaction scores.',
                        ]}
                        stack="Javascript, Typescript, Svelte, Playwright, Azure"
                    />
                    <ExperienceItem
                        title="Full Stack Web Developer"
                        location="Altus Tech Solutions Inc., Malolos City, Bulacan"
                        date="November 2020 – May 2021"
                        descriptions={[
                            'Developed a comprehensive school management information system.',
                            'Resolved technical issues, optimized web functionality, and improved speed through bug fixes and feature enhancements.',
                        ]}
                        stack="Laravel, PHP, VueJS, MongoDB, AWS"
                    />
                    <ExperienceItem
                        title="Full Stack Web Developer"
                        location="La Consolacion University Philippines"
                        date="March 2017 – May 2020"
                        descriptions={[
                            'Enhanced security protocols by implementing encryption measures and regular vulnerability assessments, resulting in a 40% decrease in cybersecurity incidents and ensuring compliance with industry standards.',
                            'Automated manual administrative processes, such as course registration and grade tracking, streamlining operations and reducing administrative workload by 20%.',
                        ]}
                        stack="React, Javascript, PHP, Laravel, VueJS"
                    />
                    {/* Add more ExperienceItem components for each experience */}
                </Grid>
                {/* Skills Section */}
                <Typography variant="h6">Skills</Typography>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    key={row.type}
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.type}
                                    </TableCell>
                                    <TableCell>{row.items}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* Education Section */}
                <Typography variant="h6" style={{ marginTop: 30 }}>
                    Education
                </Typography>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableBody>
                            {educations.map((education) => (
                                <TableRow
                                    key={education.id}
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        <Grid
                                            container
                                            justifyContent="space-between"
                                            flexDirection="row"
                                        >
                                            <Grid sx={{ fontWeight: 'bold' }}>
                                                {education.title}
                                            </Grid>
                                            <Grid>{education.date}</Grid>
                                        </Grid>

                                        {education.location}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                </Grid>
            </Container>
    );
}

export default Resume;
